import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Unauthorized Access</h1>
      <p>
        The link you used is not valid or you do not have permission to view
        this page.
      </p>
    </div>
  );
};

export default ErrorPage;
